<template>
  <v-card light class="d-flex flex-column">
    <v-img :src="require('@/assets/dac_banner.jpg')" class="grey lighten-2" height="400" width="100%">

<v-container>
  <v-row class="mt-16"><v-col></v-col></v-row>
  <v-row class="mt-16"><v-col></v-col></v-row>
  <v-row class="mt-16"><v-col></v-col></v-row>
</v-container>

        <v-img :src="require('@/assets/the_dac_guide_text.png')" width="800"></v-img>

    </v-img>

  </v-card>
</template>

<script>
  export default {
    name: 'HomeBanner',
  }
</script>
